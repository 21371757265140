


































import { Component, Vue } from "vue-property-decorator";
import NinetyNineLogo from "../components/base/NinetyNineLogo.vue";

@Component({
  components: {
    NinetyNineLogo,
  },
})
export default class ErrorView extends Vue {}
